.site-layout-content {
    min-height: 280px;
    padding: 0px;
    margin-top: 36px;
    background: #fff;
    max-width:1440px;
    margin-left:auto;
    margin-right:auto;

}

.ant-layout{
    background:#fff!important;
}
.ant-layout-header .logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;

}

.loginSpin {
    background-color: rgba(75,77,79,0.39);
    position: fixed!important;
    display: flex!important;
    min-width: 100%;
    /* z-index: 999; */
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100%;
    vertical-align: middle;
    margin: 0;
    left: 0!important;
    top: 0!important;
}

.manyosSpin{
    top:0px!important;
    left:0px!important;
    position:relative!important;
    font-size: 200px!important;

}

.ant-card-head{
    border-bottom:none;
}

.sc-error-alert{
    text-align: left;
}

.browse-no-pic{
    height:170px;
    width:300px;
    background-color:#f0f2f5;
    text-align: center;
    vertical-align: middle;
    line-height: 170px;
}

.block-end>span{
    text-align: center;
    color:#cccbcb;
    display: table;
    padding: 0px 18px;
    margin-left: auto;
    position: relative;
    top: 17px;
    background-color: #fff;
    margin-right: auto;
}
.block-end>Button{
    /* position: relative; */
    /* right: 32px; */
    top: 15px;
    margin-left: 26px;
    /* margin-right: 0px; */
    float: right;
    background-color: #fff;
}
.block-end{
    border-bottom: 1px dashed;
    border-color: #cccbcb;
    margin-bottom: 40px;
    height: 30px;
    width: 100%;
}

.divSpacer{
    height:12px;
}

.floating-div{
    float:left;
}

.ant-col-12{
    width: 50%!important;
}
.ant-col-24{
    width: 100%!important;
}
.ant-col-6{
    width: 25%!important;
}
.ant-col-18{
    width: 75%!important;
}

.element{
    margin-left:5px;
    margin-right:5px;
}

.builder-props-label{
    width: 100px;
    margin-right:8px;
    float:left;
    margin-bottom: 8px;
}
.builder-props-field{
    width: 320px;
    float:left;
    margin-bottom: 8px;
}
.builder-props-switch{

    float:left;
    margin-bottom: 8px;
    margin-right: 100px;
}
.builder-del-element{

    position:absolute;
    right:-15px;
    top:-15px;
}


.builder-field{
    width: 100%;
    position:relative;

    margin: 8px;
    min-height:30px;
    text-align: center;
    vertical-align: middle;
    border:1px dashed gray;
    line-height: 30px;

}

.builder-field:hover {

    border: 1px dashed lightblue;
    cursor: pointer
}

.steps-content {
    height: calc(100% - 100px);
    padding:20px;

    background-color: #fafafa;
    border: 1px dashed #e9e9e9;
    border-radius: 2px;
}

.steps-action {
    margin-top: 24px;
}

.ant-upload-select-picture-card {
    width: 300px!important;
    height: 170px!important;
}
.ant-upload-list-picture-card-container{
    width: 300px!important;
    height: 170px!important;
}
.ant-upload-picture-card-wrapper{
    width:300px!important;
}

.ant-tabs {
    float:left;
}

.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img{
    object-fit: cover
}


.ant-menu{
    background-color: rgba(45,48,50,0.8);
    border-color: rgba(45,48,50,0.8);

}
.ant-menu,.ant-menu a, .ant-menu button, .ant-menu-submenu-title > .ant-menu-submenu-arrow,.top-tabs-right{
    color: rgba(255, 255, 255, 0.7)!important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
    background-color: #faad148a;
}

.ant-menu-item-selected a, .ant-item-menu-selected a:hover,.ant-menu-item-selected , .ant-item-menu-selected:hover {
    color:white!important;

}
.ant-menu-item-selected , .ant-item-menu-selected:hover{

    background-color: #EF7721;
 }
.ant-menu-inline .ant-menu-item::after{
    border:3px solid #EF7721;
}
.ant-menu-submenu-title:hover {
    color: gray;
}
.ant-menu-item a:hover,.ant-menu-item button:hover, .ant-menu-item:hover,.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow{
    color: gray!important;
}

.ant-input-search-button{
    display:none;
}
.ant-menu-item-selected button{
    color: white!important;

}
.ant-page-header{
    background-color: rgba(45,48,50,0.8);
}
.rightHeader{
    background-color: #EF7721;
    padding:0;
}

.ant-layout-sider,.ant-menu-sub.ant-menu-inline{
    background-color: rgba(105, 105, 105, 0.8);
    color:rgba(0, 0, 0, 0.85);
}

.ant-page-header-heading-title{
    color:rgba(255, 255, 255 , 0.85);
}

.rightHeader  .ant-page-header-heading{
    padding:16px 24px;
}

.ant-switch-checked{
    background-color: #7b4949;
}

.rightHeader  .ant-page-header-content{

    padding-top:0px;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: gray!important;
}
.ant-tabs-tab:hover{
    color:white!important;


}

.ant-input-search, .ant-input:hover, .ant-input-search .ant-input:focus,.ant-btn:hover, .ant-btn:focus, .ant-input-search .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary), .ant-input-search .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary){
    border-color:#EF7721;
    border-left-color:#EF7721 ;
}

.top-tabs-right > .ant-tabs-nav{
    background-color: #EF7721;
    padding:0 20px;

}
.sub-tabs-right > .ant-tabs-nav{

    padding:0 20px;

}
.ant-tabs-ink-bar{
    background:gray
}

.ant-btn-primary {

    background: #EF7721;
    border-color: #EF7721;

}

.ant-btn-primary:hover , .ant-btn-primary:visited, .ant-btn-primary:active, .ant-btn-primary:focus{
    color:#fff!important;
    background: #ef7721;
    border-color: #EF7721;

}

.ant-btn:hover, .ant-btn:active, .ant-btn:focus {

  color: #ef7721;

}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #ef7721;
    border-right-width: 1px !important;
}
.ant-select:not(.ant-select-disabled):focus .ant-select-selector {
    border-color: #ef7721;
    border-right-width: 1px !important;
}

.ant-select:not(.ant-select-disabled):active .ant-select-selector {
    border-color: #ef7721;
    border-right-width: 1px !important;
}

.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
    color:#ef7721;
}
.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
    border-color: #ef7721;
}

ul[id="clients$Menu"],ul[id="scripts$Menu"]{
    overflow-y:scroll;
    max-height: calc(100vh - 350px);
}
